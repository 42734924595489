<template>
  <header
    class="header"
    :class="{
      hide: hide
    }"
  >
    <div class="header__grid container">
      <div
        class="header__grid__item"
        @click="scrollToTop()"
      >
        <img
          src="@/static/svg/blue-black-text-logo.svg"
          class="header__grid__item__logo"
        >
      </div>

      <div class="header__grid__item gap40">
        <a
          href="#about"
          class="header__grid__item__link ff-NekstMedium"
        >
          О нас
        </a>

        <a
          href="#how-to-earn"
          class="header__grid__item__link ff-NekstMedium"
        >
          Как заработать
        </a>

        <a
          href="#reviews"
          class="header__grid__item__link ff-NekstMedium"
        >
          Отзывы
        </a>

        <a
          href="#contacts"
          class="header__grid__item__link ff-NekstMedium"
        >
          Контакты
        </a>
      </div>

      <div class="header__grid__item gap30 flex-end">
        <div
          class="header__grid__item__presentations ff-NekstMedium"
          @click.stop="controlVideo"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.6426 11.1343C16.3092 11.5192 16.3092 12.4814 15.6426 12.8663L10.9283 15.5881C10.2616 15.973 9.42829 15.4919 9.42829 14.7221L9.42829 9.27849C9.42829 8.50868 10.2616 8.02756 10.9283 8.41246L15.6426 11.1343Z" fill="#2F57E9"/>
            <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#2F57E9"/>
          </svg>
          
          Презентация компании
        </div>

        <a
          href="https://account.onlix.io/ru/auth/sign-up?utm_source=landing&utm_medium=direct&utm_campaign=kz-landing-1"
          target="_blank"
          class="header__grid__item__button ff-NekstSemiBold color-blue ui-button-fill"
        >
          Стать партнёром
        </a>
      </div>
    </div>
  </header>

  <div
    v-if="isVideo"
    class="video-presentation"
  >
    <iframe
      v-click-outside="controlVideoHide"
      src="https://www.youtube.com/embed/PI4uSvyVRy0"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    />
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  data () {
    return {
      scrolltop: 0,
      hide: false,
      isVideo: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeUnmount () {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll () {
      const scroll = window.scrollY

      if (scroll <= 85) {
        this.scrolltop = scroll
        this.hide = false
        return 0
      }

      if (scroll > this.scrolltop) {
        this.scrolltop = scroll
        this.hide = true
      } else {
        this.scrolltop = scroll
        this.hide = false
      }
    },
    scrollToTop () {
      window.scrollTo(0, 0)
    },
    controlVideo () {
      this.isVideo = true
      document.body.style.overflow = 'hidden'
    },
    controlVideoHide () {
      this.isVideo = false
      document.body.style.overflow = 'auto'
    }
  }
}
</script>

<style lang="scss" scoped>
header.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #F0F2F8;
  transition: .2s;
  z-index: 10;

  @media screen and (max-width: 768px) {
    &.hide {
      transform: translateY(0%) !important;
      opacity: 1 !important;
    }
  }

  &.hide {
    transform: translateY(-100%);
    opacity: 0;
  }

  .header__grid {
    padding: 25px 17px;
    display: grid;
    grid-template-columns: 120px auto auto;
    gap: 100px;

    @media screen and (max-width: 768px) {
      padding: 12px 17px;
    }

    .header__grid__item {
      display: flex;
      align-items: center;
      white-space: nowrap;

      &.gap40 {
        gap: 40px;
      }

      &.gap30 {
        gap: 30px;
      }

      &.flex-end {
        justify-content: flex-end;
      }

      .header__grid__item__logo {
        height: 32px;
        cursor: pointer;

        @media screen and (max-width: 768px) {
          max-height: 24px !important;
          border: 1px solid red;
        }
      }

      .header__grid__item__link {
        text-transform: uppercase;
        text-decoration: none;
        transition: .2s;
        cursor: pointer;

        &:hover {
          color: #2F57E9;
        }
      }

      .header__grid__item__presentations {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
      }

      .header__grid__item__button {
        padding: 11px 44px;
        background: rgba(47, 87, 233, .2);
        border-radius: 30px; 
        text-transform: uppercase;
        text-decoration: none;

        @media screen and (max-width: 768px) {
          padding: 11px 20px;
        }
      }
    }
  }
}

.video-presentation {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 17px;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, .4);
  z-index: 11;

  iframe {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    aspect-ratio: 16 / 9;
    border-radius: 10px;
  }
}

@media screen and (max-width: 1280px) {
  header.header {
    .header__grid {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      .header__grid__item {
        &.gap40 {
          gap: 20px;
        }

        &.gap30 {
          gap: 20px;
        }

        .header__grid__item__logo {
          height: 32px;
          min-width: 120px;
          cursor: pointer;
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  header.header {
    .header__grid {
      .header__grid__item {
        &.gap40 {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  header.header {
    .header__grid {
      .header__grid__item {
        .header__grid__item__presentations {
          display: none;
          visibility: hidden;
        }
      }
    }
  }
}
</style>
