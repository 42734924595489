<template>
  <div class="container">
    <div
      class="banner-block"
      :style="{
        'background-image': `url(${require('@/static/image/banner/banner-desktop-backgrount.jpg')})`
      }"
    >
      <div class="banner-block__content">
        <div class="banner-block__content__container">
          <img
            v-if="width > 768"
            src="@/static/image/banner/banner-desktop-left-image.png"
          >

          <img
            v-else
            src="@/static/image/banner/banner-mobile-left-image.png"
          >

          <a
            href="https://account.onlix.io/ru/auth/sign-up?utm_source=landing&utm_medium=direct&utm_campaign=kz-landing-1"
            target="_blank"
            class="banner-block__content__container__button ff-NekstSemiBold ui-button-fill"
          >
            Зарегистрироваться
          </a>

          <p class="ff-NekstSemiBold color-white">
            Регистрируйтесь и участвуйте в розыгрыше 100 ТОПовых гаджетов!
          </p>
        </div>
      </div>

      <img
        src="@/static/image/banner/banner-desktop-right-image.png"
        class="banner-block__right"
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-block {
  position: relative;
  height: 378px;
  width: 100%;
  margin: 120px 0;
  border-radius: 15px;
  background-size: cover;
  background-repeat: no-repeat;

  .banner-block__content {
    position: relative;
    height: 100%;
    padding: 50px 40px 25px;
    z-index: 2;

    .banner-block__content__container {
      height: 100%;
      max-width: 560px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .banner-block__content__container__button {
        height: 46px;
        width: 100%;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #D3FF53;
        border-radius: 30px;
        font-size: 14px;
        text-transform: uppercase;
        text-decoration: none;
      }

      p {
        font-size: 16px;
        line-height: 120%;
      }
    }
  }

  .banner-block__right {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
  }
}

@media screen and (max-width: 768px) {
  .banner-block {
    height: auto;
    margin: 90px 0 60px;
    border-radius: 10px;
    overflow: hidden;

    .banner-block__content {
      position: relative;
      height: 100%;
      padding: 20px 15px 15px;
      z-index: 2;

      .banner-block__content__container {
        max-width: 100%;

        img {
          height: auto;
          width: auto;
          max-width: 220px;
        }

        .banner-block__content__container__button {
          width: 100%;
          margin: 20px 0 15px;
        }

        p {
          width: 100%;
          padding: 5px 35px;
          background: rgba(156, 34, 34, .4);
          backdrop-filter: blur(20px);
          border-radius: 6px;
          font-size: 13px;
          line-height: 120%;
          text-align: center;
        }
      }
    }

    .banner-block__right {
      position: absolute;
      top: 0;
      right: -155px;
      height: 100%;
    }
  }
}
</style>
