<template>
  <div
    v-if="modelValue"
    class="thank-you"
  >
    <div
      class="thank-you__content"
      v-click-outside="popDown"
    >
      <div class="thank-you__content__control">
        <div
          class="thank-you__content__control__close"
          @click="popDown()"
        >
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L5 5M5 5L9 9M5 5L1 9M5 5L9 1" stroke="#787C8D" stroke-width="2" stroke-linecap="round"/>
          </svg>
        </div>
      </div>

      <img src="@/static/image/pop-up-gradient.png">

      <div class="thank-you__content__padding">
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20 0H40V20L20 40H0V20L20 0Z" fill="#2F57E9" fill-opacity="0.2"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M40 40H20L0 60V80H20L40 60V80H60L80 60V40H60L80 20V0H60L40 20V40ZM40 40H60L40 60V40Z" fill="#2F57E9" fill-opacity="0.2"/>
          <g clip-path="url(#clip0_7069_85976)">
          <path d="M60 24.5834C60 22.0551 57.9449 20 55.4166 20H24.5834C22.0551 20 20 22.0551 20 24.5834V55.4166C20 57.9449 22.0551 60 24.5834 60H55.4166C57.9449 60 60 57.9449 60 55.4166V24.5834Z" fill="#2F57E9"/>
          <path d="M50.1367 33.4049C50.7883 34.0565 50.7883 35.1096 50.1367 35.7615L39.3033 46.5946C38.9783 46.9196 38.5516 47.0832 38.125 47.0832C37.6984 47.0832 37.2717 46.9196 36.9467 46.5946L31.5302 41.178C30.8783 40.5265 30.8783 39.473 31.5302 38.8215C32.1817 38.1696 33.2349 38.1696 33.8867 38.8215L38.125 43.0598L47.7802 33.4049C48.4317 32.7531 49.4849 32.7531 50.1367 33.4049Z" fill="#D3FF53"/>
          </g>
          <defs>
          <clipPath id="clip0_7069_85976">
          <rect width="40" height="40" fill="white" transform="translate(20 20)"/>
          </clipPath>
          </defs>
        </svg>

        <div class="thank-you__content__padding__title ff-NekstSemiBold color-lemone">
          Спасибо за обращение!
        </div>

        <div class="thank-you__content__padding__text">
          Заявка принята, скоро вам перезвонит консультант OnliX
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'click-outside-vue3'

export default {
  directives: { clickOutside: vClickOutside.directive },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    document.body.style.overflow = 'hidden'
  },
  methods: {
    popDown () {
      this.$emit('update:modelValue', false)
      document.body.style.overflow = 'auto'
    }
  }
}
</script>

<style lang="scss" scoped>
.thank-you {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  padding: 0 17px;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, .4);
  z-index: 10;

  .thank-you__content {
    position: relative;
    width: 100%;
    max-width: 430px;
    margin: 0 auto;
    background: #0E0E1B;
    border-radius: 15px;

    .thank-you__content__control {
      padding: 8px 8px 0px;
      display: flex;
      justify-content: flex-end;

      .thank-you__content__control__close {
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        border: 1px solid #9CA3B8;
        border-radius: 50%;
        cursor: pointer;

        svg {
          margin: 0 auto;
        }
      }
    }

    img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      object-fit: cover;
    }

    .thank-you__content__padding {
      position: relative;
      padding: 0 20px 30px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      z-index: w;

      svg {
        margin: 0 auto;
      }

      .thank-you__content__padding__title {
        font-size: 22px;
        line-height: 120%;
        text-align: center;
      }

      .thank-you__content__padding__text {
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        color: #FFFFFF;
      }
    }
  }
}
</style>
