<template>
  <div class="container">
    <a class="anchor" name="about"></a>
    <div class="about-block">
      <div class="about-block__name">
        Салют, на связи OnliX!
      </div>

      <div class="about-block__flex">
        <div class="about-block__flex__start ff-NekstBold">
          Постоянно расширяя рынок сбыта и увеличивая количество закупок, за счет партнерской программы, мы даем возможность <span class="color-blue">заработать каждому.</span>
        </div>

        <div class="about-block__flex__end ff-InterRegular">
          <div class="about-block__flex__end__text">
            Мы разработали собственную систему масштабирования бизнеса и увеличения оборота. С OnliX вы сможете зарабатывать независимо от опыта, образования и профессии. 
          </div>

          <div class="about-block__flex__end__text">
            <span class="color-blue">Выбирайте контейнер,</span> подходящие условия доходности и выплат, и получайте первый Доход уже на следующий день. Бонусные программы работают с привязкой к определенным периодам, так что вам не придется долго ждать результат. 
          </div>

          <div class="about-block__flex__end__text">
            Мы стремимся, чтобы успех был реальностью для каждого.
          </div>
        </div>
      </div>

      <VideoPlayer
        :poster="pathToPoster"
        src="https://storage.onlix.io/videos/1.mp4"
      />
    </div>
  </div>
</template>

<script>
import VideoPlayer from '@/components/ui/VideoPlayer.vue'

export default {
  components: {
    VideoPlayer
  },
  data () {
    return {
      pathToPoster: null
    }
  },
  mounted () {
    this.pathToPoster = require('@/static/image/about-video-player.png')
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin: 190px auto;

  .about-block {
    .about-block__name {
      margin-bottom: 60px;
      font-size: 18px;
      text-transform: uppercase;
    }

    .about-block__flex {
      margin-bottom: 100px;
      display: flex;
      gap: 60px;

      .about-block__flex__start {
        max-width: 840px;
        font-size: 65px;

        span {
          font-family: inherit;
          font-size: inherit;
        }
      }

      .about-block__flex__end {
        max-width: 300px;
        margin-top: 85px;
        display: flex;
        flex-direction: column;
        gap: 15px;

        .about-block__flex__end__text {
          font-size: 16px;
          font-family: inherit;
          line-height: 140%;
          text-transform: uppercase;

          span {
            font-size: inherit;
            font-family: inherit;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin: 80px auto;

    .about-block {
      .about-block__name {
        margin-bottom: 30px;
        font-size: 14px;
      }

      .about-block__flex {
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        gap: 40px;

        .about-block__flex__start {
          max-width: 100%;
          font-size: 30px;
        }

        .about-block__flex__end {
          max-width: 100%;
          margin-top: 0;
          margin-left: 40px;
        }
      }
    }
  }
}
</style>
