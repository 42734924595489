<template>
  <div class="container-block container">
    <div class="container-block__title ff-NekstBold">
      Минимальная сумма оплаты контейнера <span class="color-blue">$ 50</span>
    </div>

    <img
      src="@/static/image/container-block.png"
    >

    <a
      href="https://account.onlix.io/ru/store?utm_source=landing&utm_medium=direct&utm_campaign=kz-landing-1"
      target="_blank"
      class="container-block__button ff-NekstSemiBold color-blue ui-button-fill"
    >
      Смотреть все предложения Onlix
    </a>
  </div>
</template>

<style lang="scss" scoped>
.container-block {
  margin: 120px auto;

  .container-block__title {
    text-align: center;
    font-size: 70px;
    line-height: 110%;

    span {
      font-size: inherit;
      line-height: inherit;
      font-family: inherit;
    }
  }

  img {
    margin: 20px 0 40px;
  }

  .container-block__button {
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(47, 87, 233, .2);
    border-radius: 100px;
    font-size: 20px;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
  }
}

@media screen and (max-width: 768px) {
  .container-block {
    margin: 90px auto 60px;

    .container-block__title {
      font-size: 36px;
    }

    img {
      margin: 30px 0;
    }

    .container-block__button {
      height: 50px;
      font-size: 14px;
    }
  }
}
</style>
