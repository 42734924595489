<template>
  <div class="container">
    <a class="anchor" name="reviews" />
  </div>
  <div class="reviews-block container">
    <div class="reviews-block__start">
      <h1 class="ff-NekstBold">
        Отзывы
      </h1>

      <div class="reviews-block__start__cont">
        <div class="reviews-block__start__cont__stars">
          <div
            v-for="item in 5"
            :key="item"
            class="reviews-block__start__cont__stars__item"
          >
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 0L15.9187 8.98278H25.3637L17.7225 14.5344L20.6412 23.5172L13 17.9656L5.35879 23.5172L8.27747 14.5344L0.636266 8.98278H10.0813L13 0Z" fill="#2F57E9"/>
            </svg>
          </div>
        </div>

        <p>
          320 отзывов
        </p>
      </div>
    </div>

    <div class="reviews-block__end">
      <Swiper
        class="reviews-block__end__swiper"
        dir="rtl"
        :slides-per-view="4"
        :space-between="15"
        loop
        :autoplay="{
          delay: 5000,
          disableOnInteraction: false,
        }"
        :breakpoints="{
          1280: {
            slidesPerView: 4
          },
          1024: {
            slidesPerView: 3
          },
          830: {
            slidesPerView: 2
          },
          768: {
            slidesPerView: 1.3,
            spaceBetween: 15
          },
          0: {
            slidesPerView: 1,
            spaceBetween: 10
          }
        }"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <SwiperSlide
          v-for="(item, index) in list"
          :key="index"
          dir="ltr"
          class="reviews-block__end__swiper__slide"
        >
          <div
            v-if="width > 768"
            class="reviews-block__end__swiper__slide__image"
          >
            <img
              :src="item.image"
            >
          </div>

          <template
            v-else
          >
            <div class="reviews-block__end__swiper__slide__top">
              <img :src="item.image">

              <div class="reviews-block__end__swiper__slide__top__info">
                <div class="reviews-block__end__swiper__slide__top__info__stars">
                  <div
                    v-for="item in 5"
                    :key="item"
                    class="reviews-block__end__swiper__slide__top__info__stars__item"
                  >
                    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#2F57E9"/>
                    </svg>
                  </div>
                </div>

                <div class="reviews-block__end__swiper__slide__top__info__name ff-NekstSemiBold">
                  {{ item.name }}
                </div>

                <div class="reviews-block__end__swiper__slide__top__info__all">
                  <p>
                    В OnliX:

                    <span>
                      {{ item.month }} месяца
                    </span>
                  </p>

                  <p>
                    Ранг:

                    <span>
                      {{ item.rang }}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div class="reviews-block__end__swiper__slide__bottom">
              {{ item.text }}
            </div>
          </template>
        </SwiperSlide>
      </swiper>

      <div class="reviews-block__end__nav">
        <div class="reviews-block__end__nav__top">
          <div class="reviews-block__end__nav__top__stars">
            <div
              v-for="item in 5"
              :key="item"
              class="reviews-block__end__nav__top__stars__item"
            >
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z" fill="#2F57E9"/>
              </svg>
            </div>
          </div>

          <div class="reviews-block__end__nav__top__name ff-NekstSemiBold">
            {{ _active.name }}
          </div>

          <div class="reviews-block__end__nav__top__info">
            <div class="reviews-block__end__nav__top__info__item">
              В OnliX:

              <span>
                {{ _active.month }} месяца
              </span>
            </div>

            <div class="reviews-block__end__nav__top__info__item">
              Ранг:

              <span>
                {{ _active.rang }}
              </span>
            </div>
          </div>

          <p>
            {{ _active.text }}
          </p>
        </div>

        <div class="reviews-block__end__nav__bottom">
          <div
            class="reviews-block__end__nav__bottom__button"
            @click="swiper.slideNext()"
          >
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 1L2 7L8 13" stroke="#202539" stroke-width="2" stroke-linecap="round"/>
            </svg>
          </div>

          <div
            class="reviews-block__end__nav__bottom__button"
            @click="swiper.slidePrev()"
          >
            <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L7 7L1 13" stroke="#202539" stroke-width="2" stroke-linecap="round"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'

import 'swiper/css'

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    width: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      swiper: null,
      activeIndexSlide: 0
    }
  },
  computed: {
    _active () {
      return this.list[this.activeIndexSlide]
    },
    list () {
      return [
        {
          id: 5,
          name: 'Хасан',
          text: 'Родился второй ребенок и денег стало не хватать. Решил искать подработку, а нашел компанию Онликс, благодаря своему младшему брату, который давно вступил и уже так скзаать проверил все на себе. Поэтому сомнений, в принципе, не было. Хотелось только, чтобы основной работе это дело не мешало, и чтоб времени хватало на семью. С Онликс это оказалось возможным, потому что сам регулируешь, сколько и когда работаешь.',
          rang: 5,
          month: 3,
          image: require('@/static/image/reviews/4.jpg')
        },
        {
          id: 6,
          name: 'Азамат',
          text: 'Пока только знакомлюсь с компанией, увидел рекламу конкурса, решил поучаствовать и выиграл робот-пылесос. Начало хорошее!)',
          rang: 5,
          month: 2,
          image: require('@/static/image/reviews/6.jpg')
        },
        {
          id: 1,
          name: 'Батырхан',
          text: 'Очень рекомендую компанию Онликс, ребята серьезные и знают, чего хотят, и готовы научить всех желающих. Я недавно вышел на пенсию, но все равно без работы сидеть не могу. А тут увидел в интернете рекламу. Почитал, ознакомился и понял, что это мне подходит, ведь тут опыт и возраст неважны. присоединился с минимальной оплатой, чтобы проверить, как все работает — оказалось, работает! Очень радует, что все объясняют и помогают.',
          rang: 3,
          month: 4,
          image: require('@/static/image/reviews/7.png')
        },
        {
          id: 2,
          name: 'Тамила',
          text: 'Онликс — реально работает! Мне подруга показала свой личный кабинет, все вложения и заработки, и я поняла, что надо пробовать! Особенно приятно, что деньги можно выводить в любой момент.',
          rang: 4,
          month: 3,
          image: require('@/static/image/reviews/1.jpg')
        },
        {
          id: 3,
          name: 'Даяна',
          text: 'До этого я уже работла с подобными компаниями — Эмвей и Эйвон. Но с Онликс пока нравится больше всего, тут вкладываться нужно побольше, конечно, но и заработок нормальный. И быстро. А еще можно собрать команду и тогда вообще кайф! Я пришла три месяца назад, и уже зарабатываю больше тысячи долларов в месяц',
          rang: 3,
          month: 2,
          image: require('@/static/image/reviews/5.jpg')
        },
        {
          id: 4,
          name: 'Мурат',
          text: 'Я много раз пытался начать продавать самостоятельно, но постоянно то поставщики подводили, то товары лежали мертвым грузом. Понимал, что не хватает кого-то, кто бы научил. А тут приятель подсказал, что есть Онликс — по сути тот же бизнес на себя, только без рисков облажаться, ведь ребята уже все просчитали и продумали заранее. Я стал партнером и не пожалел ни разу — выплаты регулярные, ни о чем не беспокоишься. Уже пригласил в команду жену и тестя!',
          rang: 6,
          month: 4,
          image: require('@/static/image/reviews/3.jpg')
        }
      ]
    }
  },
  methods: {
    onSwiper (e) {
      this.swiper = e
    },
    onSlideChange (e) {
      this.activeIndexSlide = e.realIndex
    }
  }
}
</script>

<style lang="scss" scoped>
.reviews-block {
  margin: 120px auto;

  @media screen and (max-width: 768px){
    margin: 60px auto;
  }

  .reviews-block__start {
    h1 {
      font-size: 65px;
      line-height: 92%;
      color: #000;
    }

    .reviews-block__start__cont {
      margin-top: 35px;

      .reviews-block__start__cont__stars {
        display: grid;
        grid-template-columns: repeat(5, 26px);
      }

      p {
        margin-top: 20px;
        font-size: 12px;
        color: #000;
        opacity: .5;
      }
    }
  }

  .reviews-block__end {
    display: flex;

    .reviews-block__end__swiper {
      .reviews-block__end__swiper__slide {
        height: 400px;
        display: flex !important;
        align-items: flex-end;

        &.swiper-slide-active {
          img {
            height: 100%;
          }
        }

        .reviews-block__end__swiper__slide__image {
          height: 400px;
          width: 100%;
          display: flex;
          align-items: flex-end;
        }

        img {
          height: 210px;
          width: 100%;
          object-fit: cover;
          object-position: top;
          transition: .2s;
        }
      }
    }

    .reviews-block__end__nav {
      width: 300px;
      min-width: 300px;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 30px;

      .reviews-block__end__nav__top {
        height: 100%;

        .reviews-block__end__nav__top__stars {
          display: flex;
          justify-content: flex-start;

          .reviews-block__end__nav__top__stars__item {
            height: 12px;
            width: 12px;

            svg {
              height: 100%;
            }
          }
        }

        .reviews-block__end__nav__top__name {
          margin: 15px 0;
          font-size: 18px;
          text-transform: uppercase;
        }

        .reviews-block__end__nav__top__info {
          display: flex;
          align-items: center;
          gap: 30px;

          .reviews-block__end__nav__top__info__item {
            font-size: 14px;
            color: #787C8D;

            span {
              color: #202539;
            }
          }
        }

        p {
          min-height: 255px;
          margin-top: 20px;
          line-height: 140%;
        }
      }

      .reviews-block__end__nav__bottom {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;

        .reviews-block__end__nav__bottom__button {
          height: 36px;
          width: 36px;
          display: flex;
          align-items: center;
          background: rgba(47, 87, 233, .2);
          border-radius: 50%;
          transition: .2s;
          cursor: pointer;

          &:hover {
            background: #2F57E9;

            svg path {
              stroke: #fff;
            }
          }

          svg {
            margin: 0 auto;

            path {
              transition: .2s;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .reviews-block {
    .reviews-block__start {
      margin-bottom: 30px;

      h1 {
        font-size: 30px;
        line-height: 100%;
      }

      .reviews-block__start__cont {
        margin-top: 10px;

        p {
          margin-top: 5px;
        }
      }
    }

    .reviews-block__end {
      position: relative;
      left: -17px;
      display: flex;
      width: calc(100% + 34px);

      .reviews-block__end__swiper {
        position: relative;

        .reviews-block__end__swiper__slide {
          height: auto;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          // gap: 20px;

          // &.swiper-slide-active {
          //   padding-right: 17px;

          //   .reviews-block__end__swiper__slide__bottom {
          //     opacity: 1;
          //   }

          //   .reviews-block__end__swiper__slide__top {
          //     img {
          //       height: 210px;
          //       width: 140px;
          //       margin: 0;
          //       transform: translateX(0);
          //     }

          //     .reviews-block__end__swiper__slide__top__info {
          //       transform: translateX(0%);
          //     }
          //   }
          // }

          .reviews-block__end__swiper__slide__top {
            width: 100%;
            padding: 0 17px;
            display: flex;
            gap: 20px;
            overflow: hidden;

            img {
              height: 210px;
              width: 140px;
              object-fit: cover;
              margin: 0;
              transition: .2s;
            }

            .reviews-block__end__swiper__slide__top__info {
              display: flex;
              flex-direction: column;

              .reviews-block__end__swiper__slide__top__info__stars {
                display: grid;
                grid-template-columns: repeat(5, 12px);
              }

              .reviews-block__end__swiper__slide__top__info__name {
                margin: 20px 0;
                font-size: 18px;
                text-transform: uppercase;
              }

              .reviews-block__end__swiper__slide__top__info__all {
                display: flex;
                flex-direction: column;
                gap: 10px;

                p {
                  font-size: 14px;
                  color: #787C8D;

                  span {
                    color: #202539;
                  }
                }
              }
            }
          }

          .reviews-block__end__swiper__slide__bottom {
            margin-top: 20px;
            padding: 0 17px;
            font-size: 16px;
            line-height: 140%;
            transition: .2s;
          }
        }
      }

      .reviews-block__end__nav {
        display: none;
      }
    }
  }
}
</style>
