import axios from 'axios'

/* Creating a new axios instance with a baseURL of process.env.VUE_API. */
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API || ''
})

export default {
  install: (app, options) => {
    app.config.globalProperties.$axios = { ...axiosInstance }
  }
}
