<template>
  <a name="how-to-earn" />
  <div class="partner-block container">
    <div class="partner-block__start">
      <div
        v-if="width > 768"
        class="partner-block__start__circles"
      >
        <div
          class="partner-block__start__circles__item"
          :style="{
            ...getRandomPosition(),
            left: '0px',
            top: '0px'
          }"
        >
          <img src="@/static/svg/partner-block-amazon.svg">
        </div>

        <div
          class="partner-block__start__circles__item"
          :style="{
            ...getRandomPosition(),
            left: '231px',
            top: '120px'
          }"
        >
          <img src="@/static/svg/partner-block-ebay.svg">
        </div>

        <div
          class="partner-block__start__circles__item"
          :style="{
            ...getRandomPosition(),
            left: '520px',
            top: '110px'
          }"
        >
          <img src="@/static/svg/partner-block-etsy.svg">
        </div>

        <div
          class="partner-block__start__circles__item"
          :style="{
            ...getRandomPosition(),
            left: '740px',
            top: '50px'
          }"
        >
          <img src="@/static/svg/partner-block-onlix.svg">
        </div>

        <div
          class="partner-block__start__circles__item"
          :style="{
            ...getRandomPosition(),
            left: '940px',
            top: '230px'
          }"
        >
          <img src="@/static/svg/partner-block-ozon.svg">
        </div>

        <div
          class="partner-block__start__circles__item"
          :style="{
            ...getRandomPosition(),
            left: '440px',
            top: '330px'
          }"
        >
          <img src="@/static/svg/partner-block-rakuten.svg">
        </div>

        <div
          class="partner-block__start__circles__item"
          :style="{
            ...getRandomPosition(),
            left: '120px',
            top: '530px'
          }"
        >
          <img src="@/static/svg/partner-block-walmart.svg">
        </div>

        <div
          class="partner-block__start__circles__item"
          :style="{
            ...getRandomPosition(),
            left: '620px',
            top: '480px'
          }"
        >
          <img src="@/static/svg/partner-block-wildberries.svg">
        </div>

        <div
          class="partner-block__start__circles__item"
          :style="{
            ...getRandomPosition(),
            left: '880px',
            top: '480px'
          }"
        >
          <img src="@/static/svg/partner-block-wish.svg">
        </div>
      </div>

      <div
        v-else
        class="partner-block__start__circles"
      >
        <div
          class="partner-block__start__circles__item"
          :style="{
            ...getRandomPosition(),
            left: '110px',
            top: '0px'
          }"
        >
          <img src="@/static/svg/partner-block-amazon.svg">
        </div>

        <div
          class="partner-block__start__circles__item"
          :style="{
            ...getRandomPosition(),
            left: '-45px',
            top: '0px'
          }"
        >
          <img src="@/static/svg/partner-block-ebay.svg">
        </div>

        <div
          class="partner-block__start__circles__item"
          :style="{
            ...getRandomPosition(),
            left: '10px',
            top: '210px'
          }"
        >
          <img src="@/static/svg/partner-block-etsy.svg">
        </div>

        <div
          class="partner-block__start__circles__item"
          :style="{
            ...getRandomPosition(),
            left: '240px',
            top: '10px'
          }"
        >
          <img src="@/static/svg/partner-block-onlix.svg">
        </div>

        <div
          class="partner-block__start__circles__item"
          :style="{
            ...getRandomPosition(),
            left: '240px',
            top: '250px'
          }"
        >
          <img src="@/static/svg/partner-block-ozon.svg">
        </div>

        <div
          class="partner-block__start__circles__item"
          :style="{
            ...getRandomPosition(),
            left: '350px',
            top: '100px'
          }"
        >
          <img src="@/static/svg/partner-block-rakuten.svg">
        </div>

        <div
          class="partner-block__start__circles__item"
          :style="{
            ...getRandomPosition(),
            left: '390px',
            top: '220px'
          }"
        >
          <img src="@/static/svg/partner-block-walmart.svg">
        </div>

        <div
          class="partner-block__start__circles__item"
          :style="{
            ...getRandomPosition(),
            left: '620px',
            top: '100px'
          }"
        >
          <img src="@/static/svg/partner-block-wildberries.svg">
        </div>

        <div
          class="partner-block__start__circles__item"
          :style="{
            ...getRandomPosition(),
            left: '880px',
            top: '100px'
          }"
        >
          <img src="@/static/svg/partner-block-wish.svg">
        </div>
      </div>

      <div class="partner-block__start__text ff-NekstBold">
        <template
          v-if="width > 768"
        >
          <p>
            Почему OnliX дает
          </p>

          <p>
            возможность
          </p>

          <p>
            заработать каждому?
          </p>
        </template>

        <template
          v-else
        >
          <p>
            Почему OnliX
          </p>

          <p>
            дает возможность
          </p>

          <p>
            заработать
          </p>

          <p>
            каждому?
          </p>
        </template>
      </div>
    </div>

    <div class="partner-block__end">
      <div class="partner-block__end__content">
        <p class="ff-InterRegular">
          Мы ЗАКУПАЕМ ТОВАРЫ КОНТЕЙНЕРАМИ, минимизируя их себестоимость. Это позволяет получать 1,000% и более прибыли с розничных продаж. Таким образом и наша компания, и каждый партнер <span class="color-blue">гарантированно имеет свой процент дохода.</span>
        </p>

        <a
          href="https://account.onlix.io/ru/auth/sign-up?utm_source=landing&utm_medium=direct&utm_campaign=kz-landing-1"
          target="_blank"
          class="partner-block__end__content__button ui-button-fill ff-NekstSemiBold color-white">
          Стать партнером
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 0
    }
  },
  methods: {
    getRandomPosition () {
      const randomPadding = Math.random() * (60 - 15) + 15

      return {
        'padding': `${this.width > 768 ? randomPadding : randomPadding / 2}px`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.partner-block {
  margin: 190px auto 120px;
  overflow: hidden;

  .partner-block__start {
    position: relative;
    padding: 150px 0 220px;

    .partner-block__start__text {
      position: relative;
      font-size: 100px;
      line-height: 92%;
      color: #000;
      z-index: 2;

      p {
        font-size: inherit;
        color: inherit;
        font-family: inherit;
        text-align: center;

        &:first-child {
          text-align: left;
        }
      }
    }

    .partner-block__start__circles {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 100%;
      width: 100%;

      .partner-block__start__circles__item {
        position: absolute;
        display: flex;
        align-items: center;
        background: #F0F2F8;
        border: 1px solid #2F57E9;
        border-radius: 50%;
        aspect-ratio: 1 / 1;

        img {
          min-width: 53px;
          margin: 0 auto;
        }
      }
    }
  }

  .partner-block__end {
    margin-top: 60px;
    padding-right: 300px;
    display: flex;
    justify-content: flex-end;

    .partner-block__end__content {
      width: 100%;
      max-width: 300px;

      p {
        font-size: 18px;
        line-height: 140%;
        text-transform: uppercase;

        span {
          font-size: inherit;
          font-family: inherit;
          line-height: inherit;
          text-transform: inherit;
        }
      }

      .partner-block__end__content__button {
        height: 46px;
        width: 100%;
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #2F57E9;
        border-radius: 30px;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .partner-block {
    .partner-block__start {
      .partner-block__start__text {
        p {
          &:first-child {
            text-align: center;
          }
        }
      }
    }

    .partner-block__end {
      margin-top: 60px;
      padding: 0;
      display: flex;
      justify-content: flex-start;
    }
  }
}

@media screen and (max-width: 768px) {
  .partner-block {
    margin: 60px auto 90px;

    .partner-block__start {
      position: relative;
      padding: 90px 0;

      .partner-block__start__text {
        font-size: 38px;
        line-height: 90%;

        p {
          font-size: inherit;
          color: inherit;
          font-family: inherit;
          text-align: center;

          &:first-child {
            text-align: left;
          }

          &:nth-child(2) {
            text-align: left;
          }

          &:nth-child(3) {
            padding-left: 20px;
            text-align: center;
          }

          &:nth-child(4) {
            text-align: right;
          }
        }
      }

      .partner-block__start__circles {
        .partner-block__start__circles__item {
          img {
            max-height: 20px;
            max-width: 55px;
          }
        }
      }
    }

    .partner-block__end {
      margin-top: 30px;
      padding: 0;
      justify-content: center;

      .partner-block__end__content {
        width: 100%;
        max-width: 100%;

        p {
          font-size: 16px;
        }

        .partner-block__end__content__button {
          margin-top: 30px;
        }
      }
    }
  }
}
</style>
