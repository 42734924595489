<template>
  <div class="main">
    <Header
      v-if="window > 768"
    />

    <MobileHeader
      v-else
    />

    <MainBlock />

    <ContainerBlock />

    <FormBlock />

    <AboutBlock />

    <ProductBlock :width="window" />

    <PartnerBlock :width="window" />

    <ReviewsBlock :width="window" />

    <BannerBlock :width="window" />

    <Footer />
  </div>
</template>

<script>
import Header from '@/components/app/Header.vue'
import MobileHeader from '@/components/app/MobileHeader.vue'
import Footer from '@/components/app/Footer.vue'

import MainBlock from '@/components/page/MainBlock.vue'
import ContainerBlock from '@/components/page/ContainerBlock.vue'
import FormBlock from '@/components/page/FormBlock.vue'
import AboutBlock from '@/components/page/AboutBlock.vue'
import ProductBlock from '@/components/page/ProductBlock.vue'
import PartnerBlock from '@/components/page/PartnerBlock.vue'
import BannerBlock from '@/components/page/BannerBlock.vue'
import ReviewsBlock from '@/components/page/ReviewsBlock.vue'

export default {
  components: {
    Header,
    MobileHeader,
    Footer,
    MainBlock,
    ContainerBlock,
    FormBlock,
    AboutBlock,
    ProductBlock,
    PartnerBlock,
    BannerBlock,
    ReviewsBlock
  },
  data () {
    return {
      window: null
    }
  },
  mounted () {
    window.scrollTo(0, 0)

    this.window = window.innerWidth
    window.onresize = () => {
      this.window = event.target.innerWidth
    }
  }
}
</script>

<style lang="scss">
@import './assets/styles/main.scss';

.main {
  padding-top: 86px;

  @media screen and (max-width: 768px) {
    padding-top: 60px;
  }
}
</style>
