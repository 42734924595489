<template>
  <div class="main-block">
    <div class="main-block__grid container">
      <div class="main-block__grid__item lemone">
        <div class="main-block__grid__item__content">
          <div class="main-block__grid__item__content__block">
            <div class="main-block__grid__item__content__block__text">
              <div class="main-block__grid__item__content__block__text__name">
                Зарабатывайте Еженедельно
              </div>

              <div class="main-block__grid__item__content__block__text__value ff-NekstSemiBold">
                от $ 140
              </div>
            </div>
          </div>

          <div class="main-block__grid__item__content__block flex-end">
            <div class="main-block__grid__item__content__block__text">
              <div class="main-block__grid__item__content__block__text__name">
                получайте годовой доход
              </div>

              <div class="main-block__grid__item__content__block__text__value ff-NekstSemiBold">
                +364%
              </div>
            </div>
          </div>

          <div class="main-block__grid__item__content__block padding">
            <div class="main-block__grid__item__content__block__text">
              <div class="main-block__grid__item__content__block__text__name">
                Зарабатывайте за год
              </div>

              <div class="main-block__grid__item__content__block__text__value ff-NekstSemiBold">
                от $ 7,280
              </div>
            </div>
          </div>

          <div class="main-block__grid__item__content__block">
            <div
              class="main-block__grid__item__content__block__button ff-NekstSemiBold color-white ui-button-fill"
              @click.stop="popUp()"
            >
              Получить консультацию
            </div>

            <ul>
              <li class="ff-NekstMedium">
                Предложение действительно для 
                
                <a
                  href="https://account.onlix.io/ru/store?utm_source=landing&utm_medium=direct&utm_campaign=kz-landing-1"
                  target="_blank"
                  class="color-blue"
                >
                  Контейнера VIP 7%
                </a>
              </li>

              <li class="ff-NekstMedium">
                Сумма оплаты Контейнера VIP 7% от $ 2000
              </li>

              <li class="ff-NekstMedium">
                Вывод дохода от $ 10, доступен 24/7
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="main-block__grid__item blue">
        <div class="main-block__grid__item__content">
          <div class="main-block__grid__item__content__block">
            <div class="main-block__grid__item__content__block__text">
              <div class="main-block__grid__item__content__block__text__name color-white">
                Зарабатывайте Еженедельно
              </div>

              <div class="main-block__grid__item__content__block__text__value ff-NekstSemiBold color-white">
                от $ 400
              </div>
            </div>
          </div>

          <div class="main-block__grid__item__content__block flex-end">
            <div class="main-block__grid__item__content__block__text">
              <div class="main-block__grid__item__content__block__text__name color-white">
                получайте годовой доход
              </div>

              <div class="main-block__grid__item__content__block__text__value ff-NekstSemiBold color-white">
                +416%
              </div>
            </div>
          </div>

          <div class="main-block__grid__item__content__block padding">
            <div class="main-block__grid__item__content__block__text">
              <div class="main-block__grid__item__content__block__text__name color-white">
                Зарабатывайте за год
              </div>

              <div class="main-block__grid__item__content__block__text__value ff-NekstSemiBold color-white">
                от $ 20,800
              </div>
            </div>
          </div>

          <div class="main-block__grid__item__content__block">
            <div
              class="main-block__grid__item__content__block__button ff-NekstSemiBold color-dark ui-button-fill"
              @click.stop="popUp()"
            >
              Получить консультацию
            </div>

            <ul>
              <li class="ff-NekstMedium color-white">
                Предложение действительно для
                
                <a
                  href="https://account.onlix.io/ru/store?utm_source=landing&utm_medium=direct&utm_campaign=kz-landing-1"
                  target="_blank"
                  class="color-lemone"
                >
                  Контейнера VIP 8%
                </a>
              </li>

              <li class="ff-NekstMedium color-white">
                Сумма оплаты Контейнера VIP 8% от $ 5000
              </li>

              <li class="ff-NekstMedium color-white">
                Вывод дохода от $ 10, доступен 24/7
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="isForm"
    class="pop-up-form"
  >
    <div
      class="pop-up-form__content"
      v-click-outside="popDown"
    >
      <div class="pop-up-form__content__control">
        <div
          class="pop-up-form__content__control__close"
          @click="popDown()"
        >
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L5 5M5 5L9 9M5 5L1 9M5 5L9 1" stroke="#787C8D" stroke-width="2" stroke-linecap="round"/>
          </svg>
        </div>
      </div>

      <img src="@/static/image/pop-up-gradient.png">

      <div class="pop-up-form__content__padding">
        <div class="pop-up-form__content__padding__title ff-NekstSemiBold color-lemone">
          Для получения консультации, введите свой номер телефона
        </div>

        <div class="pop-up-form__content__padding__text">
          Консультант Onlix свяжется с вами в рабочее время и поможет найти оптимальное предложение пассивного заработка
        </div>

        <div class="pop-up-form__content__padding__input">
          <input
            ref="input"
            type="text"
            placeholder="Номер телефона*"
            v-model="phone"
            v-maska data-maska="+7 (####) ###-##-##"
            class="ff-NekstMedium"
            :class="{
              error: error
            }"
          >

          <p
            v-if="error"
            class="pop-up-form__content__padding__input__error"
          >
            {{ error }}
          </p>
        </div>

        <button
          class="pop-up-form__content__padding__button ui-button-fill ff-NekstSemiBold color-white"
          :disabled="loading"
          @click="submit"
        >
          Оставить контакт
        </button>
      </div>
    </div>
  </div>

  <ThankYou
    v-if="isThankYou"
    v-model="isThankYou"
  />
</template>

<script>
import vClickOutside from 'click-outside-vue3'
import { vMaska } from "maska"

import ThankYou from '@/components/app/ThankYou.vue'

export default {
  directives: { maska: vMaska, clickOutside: vClickOutside.directive },
  components: {
    ThankYou
  },
  data () {
    return {
      loading: false,
      phone: '',
      error: null,
      isForm: false,
      isThankYou: false
    }
  },
  methods: {
    async submit () {
      try {
        this.loading = true
        this.setError()

        if (!this.error) {
          const phone = `+${this.phone.replace(/[^\d;]/g, '')}`

          const response = await this.$axios.post(
            'shared/landing/lead', { phone })

          if (response.status === 200) {
            this.phone = ''
            this.error = null
            this.popDown()
            this.isThankYou = true
          }
        }
      } catch (e) {
        this.error = 'Ошибка!'
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    setError () {
      this.error = null

      if (this.phone.length < 19) {
        this.error = 'Это поле обязательно к заполнению'
        return
      }
    },
    popUp () {
      this.isForm = true
      document.body.style.overflow = 'hidden'
    },
    popDown () {
      this.isForm = false,
      document.body.style.overflow = 'auto'
      this.phone = ''
      this.error = null
    }
  }
}
</script>

<style lang="scss" scoped>
.main-block {
  width: 100%;
  overflow: hidden;

  .main-block__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .main-block__grid__item {
      position: relative;
      padding: 80px 0 30px;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        width: 100vw;
        background: inherit;
      }

      &.lemone {
        padding-right: 80px;
        background: #D3FF53;

        &::before {
          right: calc(100% - 10px);
        }
      }

      &.blue {
        padding-left: 80px;
        background: #2F57E9;

        &::before {
          left: calc(100% - 10px);
        }

        .main-block__grid__item__content {
          .main-block__grid__item__content__block {
            .main-block__grid__item__content__block__button {
              background: #D3FF53;
            }
          }
        }
      }

      .main-block__grid__item__content {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 40px;
        z-index: 2;

        .main-block__grid__item__content__block {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          gap: 30px;

          &.flex-end {
            align-items: flex-end;

            .main-block__grid__item__content__block__text {
              text-align: right;
            }
          }

          .main-block__grid__item__content__block__text {
            display: flex;
            flex-direction: column;
            gap: 30px;
            text-align: left;

            .main-block__grid__item__content__block__text__name {
              font-size: 16px;
              text-transform: uppercase;
            }

            .main-block__grid__item__content__block__text__value {
              font-size: 70px;
            }
          }

          .main-block__grid__item__content__block__button {
            height: 60px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #2F57E9;
            border-radius: 30px;
            text-transform: uppercase;
          }

          ul {
            margin: 0;
            padding: 0 0 0 22px;

            li {
              margin-top: 10px;

              &:first-child {
                margin: 0;
              }

              a {
                font-family: inherit;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}

.pop-up-form {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 17px;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, .4);
  z-index: 10;

  .pop-up-form__content {
    position: relative;
    width: 100%;
    max-width: 430px;
    margin: 0 auto;
    background: #0E0E1B;
    border-radius: 15px;

    .pop-up-form__content__control {
      padding: 8px 8px 0px;
      display: flex;
      justify-content: flex-end;

      .pop-up-form__content__control__close {
        height: 20px;
        width: 20px;
        display: flex;
        align-items: center;
        border: 1px solid #9CA3B8;
        border-radius: 50%;
        cursor: pointer;

        svg {
          margin: 0 auto;
        }
      }
    }

    img {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      object-fit: cover;
    }

    .pop-up-form__content__padding {
      position: relative;
      padding: 0 20px 30px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      z-index: 2;

      .pop-up-form__content__padding__title {
        font-size: 22px;
        line-height: 120%;
        text-align: center;
      }

      .pop-up-form__content__padding__text {
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        color: #FFFFFF;
      }

      .pop-up-form__content__padding__input {
        position: relative;
        width: 100%;

        input {
          height: 46px;
          width: 100%;
          padding: 0 20px;
          background: #F0F2F8;
          border-radius: 5px;
          border: none;
          outline: none;
          font-size: 16px;

          &.error {
            border: 2px solid #DA2828;
          }

          &::placeholder {
            color: #9CA3B8;
          }
        }

        .pop-up-form__content__padding__input__error {
          margin-top: 10px;
          color: #DA2828;
        }
      }

      .pop-up-form__content__padding__button {
        height: 46px;
        padding: 16px 35px;
        background: #2F57E9;
        border-radius: 30px;
        white-space: nowrap;
        text-transform: uppercase;
        text-align: center;
        cursor: pointer;

        &:disabled {
          cursor: default;
        }
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .main-block {
    .main-block__grid {
      .main-block__grid__item {
        &.lemone {
          padding-right: 20px;
        }

        &.blue {
          padding-left: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .main-block {
    width: 100%;
    overflow: hidden;

    .main-block__grid {
      grid-template-columns: repeat(1, 1fr);

      .main-block__grid__item {
        position: relative;
        left: -17px;
        width: calc(100% + 34px);
        padding: 25px 17px 20px;

        &.lemone {
          padding-right: 17px;
        }

        &.blue {
          padding-left: 17px;
        }

        .main-block__grid__item__content {
          position: relative;
          gap: 10px;

          .main-block__grid__item__content__block {
            gap: 20px;
            // border: 1px solid red;

            &.padding {
              position: relative;
              top: -10px;
              // padding-left: 40px;
              margin-bottom: 5px;
            }

            &.flex-end {
              position: relative;
              top: 5px;
              right: 0;
              margin-top: 5px;
              align-items: flex-end;

              .main-block__grid__item__content__block__text {
                text-align: right;
              }
            }

            .main-block__grid__item__content__block__text {
              display: flex;
              flex-direction: column;
              gap: 10px;
              text-align: left;

              .main-block__grid__item__content__block__text__name {
                max-width: 100%;
                font-size: 12px;
                line-height: 110%;
              }

              .main-block__grid__item__content__block__text__value {
                font-size: 36px;
              }
            }

            .main-block__grid__item__content__block__button {
              height: 50px;
              margin-top: 15px;
            }

            ul {
              margin: 0;
              padding: 0 0 0 22px;

              li {
                margin-top: 10px;

                &:first-child {
                  margin: 0;
                }

                span {
                  font-family: inherit;
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
