<template>
  <div class="container">
    <div class="form-block">
      <div class="form-block__text ff-NekstSemiBold color-white">
        Получите персональное предложение!
      </div>

      <div class="form-block__input">
        <input
          type="text"
          placeholder="Номер телефона*"
          v-model="phone"
          v-maska data-maska="+7 (####) ###-##-##"
          class="ff-NekstMedium"
          :class="{
            error: error
          }"
        >

        <p
          v-if="error"
          class="form-block__input__error"
        >
          {{ error }}
        </p>
      </div>

      <button
        class="form-block__button ui-button-fill ff-NekstSemiBold color-white"
        :disabled="loading"
        @click="submit"
      >
        получить предложение
      </button>
    </div>
  </div>

  <ThankYou
    v-if="isThankYou"
    v-model="isThankYou"
  />
</template>

<script>
// countries-phone-masks
import { vMaska } from "maska"
import ThankYou from '@/components/app/ThankYou.vue'

export default {
  directives: { maska: vMaska },
  components: { ThankYou },
  data () {
    return {
      loading: false,
      phone: '',
      error: null,
      isThankYou: false
    }
  },
  methods: {
    async submit () {
      try {
        this.loading = true
        this.setError()

        if (!this.error) {
          const phone = `+${this.phone.replace(/[^\d;]/g, '')}`

          const response = await this.$axios.post(
            'shared/landing/lead', { phone })

          if (response.status === 200) {
            this.phone = ''
            this.error = null
            this.isThankYou = true
          }
        }
      } catch (e) {
        this.error = 'Ошибка!'
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    setError () {
      this.error = null

      if (this.phone.length < 19) {
        this.error = 'Это поле обязательно к заполнению'
        return
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin: 120px auto;

  .form-block {
    padding: 65px 30px 65px 40px;
    display: flex;
    align-items: center;
    background: linear-gradient(94.38deg, rgba(14, 14, 27, 0.3) 0%, rgba(47, 87, 233, 0.3) 100%), #0E0E1B;
    border-radius: 15px;

    .form-block__text {
      min-width: 330px;
      font-size: 26px;
      line-height: 130%;
    }

    .form-block__input {
      position: relative;
      width: 100%;
      margin: 0 25px 0 50px;

      input {
        height: 46px;
        width: 100%;
        padding: 0 20px;
        background: #F0F2F8;
        border-radius: 5px;
        border: none;
        outline: none;
        font-size: 16px;

        &.error {
          border: 2px solid #DA2828;
        }

        &::placeholder {
          color: #9CA3B8;
        }
      }

      .form-block__input__error {
        position: absolute;
        top: 100%;
        left: 0;
        margin-top: 10px;
        color: #DA2828;
      }
    }

    .form-block__button {
      height: 46px;
      padding: 16px 35px;
      background: #2F57E9;
      border-radius: 30px;
      white-space: nowrap;
      text-transform: uppercase;
      text-align: center;
      cursor: pointer;

      &:disabled {
        cursor: default;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .container {
    .form-block {
      padding: 35px 25px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .form-block__text {
        min-width: 330px;
        font-size: 26px;
        line-height: 130%;
      }

      .form-block__input {
        max-width: 490px;
        margin: 20px 0;

        input {
          height: 46px;
          width: 100%;
          padding: 0 20px;
          background: #F0F2F8;
          border-radius: 5px;
          border: none;
          outline: none;
          font-size: 16px;

          &::placeholder {
            color: #9CA3B8;
          }
        }

        .form-block__input__error {
          position: static;
        }
      }

      .form-block__button {
        height: 46px;
        padding: 16px 35px;
        background: #2F57E9;
        border-radius: 30px;
        white-space: nowrap;
        text-transform: uppercase;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin: 60px auto;

    .form-block {
      .form-block__text {
        min-width: none;
      }

      .form-block__input {
        max-width: 100%;

        input {
          width: 100%;
          max-width: 100%;
        }
      }

      .form-block__button {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
</style>
